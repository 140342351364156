<template>
  <div class="editionToolbar">
    <div class="leaflet-bar">
      <a
        v-if="showDrawTool || isEditing"
        :class="{ active: isSnapEnabled }"
        :title="`${ isSnapEnabled ? 'Désactiver' : 'Activer' } l'accrochage aux points`"
        @click="toggleSnap"
      >
        <i
          class="magnet icon"
          aria-hidden="true"
        />
        <span class="sr-only">{{ isSnapEnabled ? 'Désactiver' : 'Activer' }} l'accrochage aux points</span>
      </a>

      <a
        v-if="showDrawTool"
        class="leaflet-draw-draw-polygon active"
        :title="`Dessiner ${editionService.geom_type === 'linestring' ? 'une' : 'un'} ${toolbarGeomTitle}`
        "
      >
        <img
          v-if="editionService.geom_type === 'linestring'"
          class="list-image-type"
          src="@/assets/img/line.png"
          alt="line"
        >
        <img
          v-if="editionService.geom_type === 'point'"
          class="list-image-type"
          src="@/assets/img/marker.png"
          alt="marker"
        >
        <img
          v-if="editionService.geom_type === 'polygon'"
          class="list-image-type"
          src="@/assets/img/polygon.png"
          alt="polygon"
        >
      </a>

      <div v-else>
        <a
          :class="{ active: isEditing }"
          :title="`Modifier ${toolbarEditGeomTitle}`"
          @click="update"
        >
          <i class="edit outline icon" />
          <span class="sr-only">Modifier {{ toolbarEditGeomTitle }}</span>
        </a>
        <a
          :title="`Supprimer ${toolbarEditGeomTitle}`"
          @click="deleteObj"
        >
          <i class="trash alternate outline icon" />
          <span class="sr-only">Supprimer {{ toolbarEditGeomTitle }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import editionService from '@/services/edition-service';

export default {

  name: 'EditingToolbar',

  props: {
    map: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      editionService: editionService,
      isEditing: false,
      isSnapEnabled: false,
    };
  },

  computed: {
    showDrawTool() {
      return this.editionService && this.editionService.editing_feature === undefined;
    },
    toolbarGeomTitle() {
      switch (this.editionService.geom_type) {
      case 'polygon':
        return 'polygone';
      case 'linestring':
        return 'ligne';
      }
      return 'point';
    },
    toolbarEditGeomTitle() {
      return `${this.editionService.geom_type === 'linestring' ? 'la' : 'le'} ${this.toolbarGeomTitle}`;
    } 
  },

  methods: {
    update() {
      editionService.activeUpdateFeature();
      this.isEditing = true;
    },
    deleteObj() {
      editionService.removeFeatureFromMap();
      this.isEditing = false;
    },
    toggleSnap() {
      if (this.isSnapEnabled) {
        editionService.removeSnapInteraction(this.map);
      } else {
        editionService.addSnapInteraction(this.map);
      }
      this.isSnapEnabled = !this.isSnapEnabled;
    }
  }
};
</script>

<style lang="less" scoped>

.editionToolbar{
  position: absolute;
  // each button have (more or less depends on borders) .5em space between
  // zoom buttons are 60px high, geolocation and full screen button is 34px high with borders
  top: calc(2em + 60px + 34px + 64px);
  right: 6px;
  border: 2px solid rgba(0,0,0,.2);
  border-radius: 4px;
  background-clip: padding-box;
  padding: 0;
  z-index: 9;
}
.leaflet-bar {

  a:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  a:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom: none;
  }

  a, .leaflet-control-layers-toggle {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
  }

  a {
    background-color: #fff;
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;

    i {
      margin: 0;
      vertical-align: middle;
      &.magnet {
        transform: rotate(90deg);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .85;
      }
    }
  }

  .active {
    background-color: rgba(255, 145, 0, 0.904);
    color: #fff;

    i {
      font-weight: bold;
    }

    img {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(282deg) brightness(105%) contrast(100%);
    }
  }

  .list-image-type {
    height: 20px;
    vertical-align: middle;
    margin: 5px 0 5px 0;
  }
  a:hover {
    cursor: pointer !important;
    background-color: #ebebeb !important;
  }
  a:focus {
    background-color: #ebebeb !important;
  }
}
</style>
